import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Landing from './Pages/Landing';
import HelplinesPage from './Pages/Helplines';
import EducatePage from './Pages/Educate';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/help',
    element: <HelplinesPage />
  },
  {
    path: '/educate',
    element: <EducatePage />
  }
])

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
