import { useEffect, useState } from "react"
import NavbarComponent from "../Components/Navbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import FooterComponent from "../Components/Footer"

const images = [
    'ambulance.jpg',
    'carcrash.jpeg',
    'carcrash3.jpeg',
    'policestock.jpg'
]

const Landing = () => {
    const [intro, setIntro] = useState(0)
    const [image, setImage] = useState()

    useEffect(() => {
        setImage(Math.floor(Math.random()*images.length))
    }, [])

    return (
        <div className='min-h-screen min-w-full bg-black'>
            <img src={`/images/backgrounds/${images[image]}`} alt="Background" className="select-none absolute inset-0 w-full h-full object-cover z-10 blur-sm brightness-50 opacity-50" />
            <div className='relative z-20'>
                <NavbarComponent />
                <div className='max-w-5xl m-auto min-w-ful p-5'>
                    <h1 className="text-white text-center font-akira text-4xl">HELPLINES AND SUPPORT</h1>
                    <h1 className="text-center font-akira text-white text-1xl">One life, one you.</h1>
                    <h1 className="text-center font-inter text-white text-2xl mt-5">If you're in immediate danger, contact emergency services as soon as possible.</h1>
                    <section className='mt-10 flex flex-col gap-y-5 overflow-auto max-w-screen'>
                        <div className='border-2 border-white bg-black p-5 flex flex-col gap-y-1'>
                            <h1 className="text-white text-left font-akira text-xl">Alcohol & Drug Counselling Online</h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'CLOCK', style: 'solid'})} />
                                Open 24/7
                            </h1>
                            <h1 onClick={() => window.open('https://www.counsellingonline.org.au/')} className="text-white text-left font-inter text-xl flex items-center gap-2 underline cursor-pointer">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'globe', style: 'solid'})} />
                                https://www.counsellingonline.org.au/
                            </h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'address-card', style: 'solid'})} />
                                About Alcohol & Drug Counselling Online
                            </h1>
                            <p className='font-inter text-white text-lg'>
                                Alcohol & Drug Counselling Online provides a 24-hour telephone counselling, information and referral service for anyone in Victoria wishing to discuss an alcohol or drug-related issue.
                                <br />
                                DirectLine is a key component of Victoria’s state-wide alcohol and drug network. It complements the network of treatment and support services in the community through the provision of multiple, immediate responses to people affected by alcohol and drug issues.
                                <br />
                                <br />
                                <b>Where is Alcohol & Drug Counselling Online available?</b><br />
                                Available nationwide in Australia

                                <br /><b>Who does Alcohol & Drug Counselling Online support?</b><br />
                                All topics and everyone

                                <br /><b>What languages does Alcohol & Drug Counselling Online support?</b><br />
                                English

                                <br /><b>What can I talk to Alcohol & Drug Counselling Online about?</b><br />
                                Substance Use and Supporting Others
                            </p>
                        </div>
                        <div className='border-2 border-white bg-black p-5 flex flex-col gap-y-1'>
                            <h1 className="text-white text-left font-akira text-xl">National Alcohol and Other Drug Hotline</h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'CLOCK', style: 'solid'})} />
                                Open 24/7
                            </h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2 underline">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'phone', style: 'solid'})} />
                                1800 250 015
                            </h1>
                            <h1 onClick={() => window.open('https://www.health.gov.au/contacts/national-alcohol-and-other-drug-hotline')} className="text-white text-left font-inter text-xl flex items-center gap-2 underline cursor-pointer">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'globe', style: 'solid'})} />
                                https://www.health.gov.au/
                            </h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'address-card', style: 'solid'})} />
                                About National Alcohol and Other Drug Hotline
                            </h1>
                            <p className='font-inter text-white text-lg'>
                                The National Alcohol and Other Drug Hotline provides 24/7 free and confidential information and support to those impacted by alcohol and other drug use by redirecting people to the relevant Alcohol and Drug Information Service in their state or territory.
                                <br />
                                When you contact us you will talk with one our friendly and supportive counselors who will listen to you with empathy and understanding, and work together with you to find a meaningful way foward. They are experienced in knowing how to help people who have challenges like what you are going through. Regardless of your age, gender, ethnicity or religious beliefs, we will listen in a supportive and non-judgmental capacity. We are ready to support you 24/7, whether it's the middle of the day or the middle of the night.
                                <br />
                                <br />
                                <b>Where is National Alcohol and Other Drug Hotline available?</b><br />
                                Available nationwide in Australia

                                <br /><b>Who does National Alcohol and Other Drug Hotline support?</b><br />
                                Everyone

                                <br /><b>What languages does National Alcohol and Other Drug Hotline support?</b><br />
                                English

                                <br /><b>What can I talk to National Alcohol and Other Drug Hotline about?</b><br />
                                Substance Use and Supporting Others

                                <br /><b>Accessibility notes</b><br />
                                When calling this helpline you will be automatically redirected to the Alcohol and Drug Information Service in your state or territory.
                            </p>
                        </div>
                        <div className='border-2 border-white bg-black p-5 flex flex-col gap-y-1'>
                            <h1 className="text-white text-left font-akira text-xl">Kids Helpline</h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'CLOCK', style: 'solid'})} />
                                Open 24/7
                            </h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2 underline">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'phone', style: 'solid'})} />
                                1800 55 1800
                            </h1>
                            <h1 onClick={() => window.open('https://www.health.gov.au/contacts/national-alcohol-and-other-drug-hotline')} className="text-white text-left font-inter text-xl flex items-center gap-2 underline cursor-pointer">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'globe', style: 'solid'})} />
                                https://kidshelpline.com.au/
                            </h1>
                            <h1 className="text-white text-left font-inter text-xl flex items-center gap-2">
                                <FontAwesomeIcon className='text-xl' icon={icon({name: 'address-card', style: 'solid'})} />
                                About Kids Helpline
                            </h1>
                            <p className='font-inter text-white text-lg'>
                            Kids Helpline, powered by yourtown, is Australia’s only free and confidential, 24/7 online and phone counselling service for young people aged 5 – 25. Free call 1800 55 1800 or www.kidshelpline.com.au. We're here whenever children and young people need us - anytime and for any reason. Our qualified counsellors are available to chat over phone, email or webchat while peer-to-peer support is available through My Circle. Our website also offers a great range of self-help resources written by counsellors specifically for young people. From mental health and wellbeing to physical health, identity, friends, family, relationships, school, work and life issues in general – we’re here to help.
                                <br />
                                <br />
                                <b>Where is Kids Helpline available?</b><br />
                                Available nationwide in Australia

                                <br /><b>Who does Kids Helpline support?</b><br />
                                All topics, youth, and children

                                <br /><b>What languages does Kids Helpline support?</b><br />
                                English

                                <br /><b>What can I talk to Kids Helpline about?</b><br />
                                Abuse and Domestic Violence, Anxiety, Bullying, Depression, Eating & Body Image, Family, Gender and Sexual Identity, Loneliness, Trauma and PTSD, Relationships, School & Work, Self-harm, Sexual Abuse, Stress, Substance Use, Suicidal Thoughts, Supporting Others and Grief and Loss
                            </p>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    )
}

export default Landing