import { useEffect, useState } from "react"
import NavbarComponent from "../Components/Navbar"
import FooterComponent from "../Components/Footer"

const images = [
    'ambulance.jpg',
    'carcrash.jpeg',
    'carcrash3.jpeg',
    'policestock.jpg'
]

const EducatePage = () => {
    const [intro, setIntro] = useState(0)
    const [image, setImage] = useState()

    useEffect(() => {
        setImage(Math.floor(Math.random()*images.length))
    }, [])

    return (
        <div className='min-h-screen min-w-full bg-black'>
            <img src={`/images/backgrounds/${images[image]}`} alt="Background" className="select-none absolute inset-0 w-full h-full object-cover z-10 blur-sm brightness-50 opacity-50" />
            <div className='relative z-20'>
                <NavbarComponent />
                <div className='max-w-5xl m-auto min-w-ful p-5'>
                    <h1 className="text-white text-center font-akira text-4xl">EDUCATE YOURSELF AND OTHERS ON THE DANGERS OF DRUGS & ALCHOHOL</h1>
                    <h1 className="text-center font-akira text-white text-1xl">One life, one you.</h1>
                    <h1 className="text-center font-inter text-white text-xl mt-5">If you're in immediate danger, contact emergency services as soon as possible.</h1>
                    <h1 className="text-center font-inter text-white text-3xl mt-5 font-black">Alcohol and other drug education programs</h1>
                    <h1 className="text-center font-inter text-white text-2xl mt-5 font-semibold">In (unofficial) partnership with the <a className="underline" href='www.betterhealth.vic.gov.au'>Better Health Channel</a>, <a className="underline" href='https://www.health.vic.gov.au/'>Victoria State Government Department of Health</a> and the <a className="underline" href='http://www.adf.org.au/'>Australian Alcohol and Drug Foundation</a> </h1>
                    <section className='mt-10 flex flex-col gap-y-5 overflow-auto max-w-screen'>
                        <div className='border-2 border-white bg-black p-5 flex flex-col gap-y-1'>
                            <h1 className="text-white text-left font-akira text-xl">Alcohol and other drug education programs for school-aged children</h1>
                            <p className='font-inter text-white text-lg'>
                            In school-aged children, alcohol and other drug education programs are usually focussed on developing resilience and good decision-making skills. Some strategies also target parents to role model responsible drinking behaviours, and guide parents on how to talk to their children about the dangers of alcohol and drugs.
                            <br/><br/>
                            The Victorian Department of Education and Training has developed age-appropriate programs (such as the <a className='underline text-blue-200 font-semibold' href='https://fuse.education.vic.gov.au/Resource/LandingPage?ObjectId=208dfd06-bfb1-4c88-a93e-5f2bbcf1dc65&SearchScope=All'>Get Ready Program</a>) for children in primary and secondary school.
                            <br/><br/>
                            The Department of Health’s <a className='underline text-blue-200 font-semibold' href='https://positivechoices.org.au/'>Positive Choices Website</a> is an online portal that helps school communities access accurate, up-to-date drug education resources and prevention programs.
                            </p>
                        </div>
                        <div className='border-2 border-white bg-black p-5 flex flex-col gap-y-1'>
                            <h1 className="text-white text-left font-akira text-xl">Alcohol and other drug education programs for adults</h1>
                            <p className='font-inter text-white text-lg'>
                            Once people are of legal drinking age, alcohol education programs focus on:
                            <br/><br/>
                            <ol className="list-disc pl-10">
                                <li>responsible drinking behaviour</li>
                                <li>promoting healthier cultural approaches to alcohol.</li>
                            </ol>
                            They may also encourage people to reduce the amount of alcohol they consume on a regular basis to reduce the chance of alcohol dependence and longer-term health problems.
                            <br/><br/>
                            For information and resources to help you make decisions about your drinking, visit the <a className='underline text-blue-200 font-semibold' href='https://www.vichealth.vic.gov.au/our-work/preventing-harm-from-alcohol'>VicHealth Website</a>.
                            <br/><br/>
                            When it comes to illicit drugs or the non-medical use of pharmaceutical medications, education programs aim to increase people’s awareness of the risks associated with specific drugs. This can include short-term risks such as overdose, or longer-term issues associated with dependence. It may also include risks associated with drug taking methods, such as injecting and poly-drug use.
                            <br/><br/>
                            Some alcohol and other drug education programs are targeted at specific population groups or settings where risky drinking can occur, such as:
                            <br/><br/>
                            <ol className="list-disc pl-10">
                                <li><a className='underline text-blue-200 font-semibold' href='http://goodsports.com.au/'>Good Sports</a> (Alcohol and Drug Foundation) which aims to improve the way alcohol is managed at sporting clubs</li>
                                <li><a className='underline text-blue-200 font-semibold' href='http://hrvic.org.au/dancewize/'>DanceWize</a> (Harm Reduction Victoria), which aims to reduce drug- and alcohol-related harm at Victorian dance parties, festivals and nightclubs through peer education</li>
                                <li><a className='underline text-blue-200 font-semibold' href='https://redfrogs.com.au/'>RedFrogs Australia</a> Australia which aims to educate and support young people in alcohol-fuelled environments, such as school-leavers during schoolies week</li>
                                <li><a className='underline text-blue-200 font-semibold' href='http://hrvic.org.au/overdose/the-project/'>Drug Overdose Prevention Education</a> (DOPE) (Harm Reduction Victoria) which delivers peer-based overdose education to users of illicit drugs</li>
                                <li><a className='underline text-blue-200 font-semibold' href='https://www.penington.org.au/community-overdose-prevention-and-education-cope/'>Community Overdose Prevention and Education</a> (COPE) (Penington Institute), which provides overdose first aid training. COPE providers also arrange access to naloxone, a medicine that reverses the effects of opioids, for people who may be at risk of or witness an opioid overdose</li>
                                <li><a className='underline text-blue-200 font-semibold' href='http://www.vcglr.vic.gov.au/home/liquor/education+and+training/responsible+service+of+alcohol/'>Responsible Service of Alcohol</a> provides training for licensees and staff selling or serving alcohol, in order to ensure licensed premises provide a safe and pleasant environment.</li>
                            </ol>
                            </p>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    )
}

export default EducatePage