import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'


const NavbarComponent = () => {
    const navigate = useNavigate()

    return (
        <div className='z-20 relative flex justify-start items-center p-2 mx-5 border-b-2 border-white'>
            <section onClick={() => navigate('/?introductionShown=1')} id='campaignName' className='cursor-pointer select-none flex flex-col text-white font-akira'>
                <h1 className='text-lg border-b-1'>
                    One life,
                </h1>
                <h1 className='text-2xl'>
                    One you.
                </h1>
            </section>
            <section className='ml-auto gap-3 hidden md:flex'>
                <button onClick={() => navigate('/?introductionShown=1')} className='flex justify-center gap-2 items-center text-white'>
                    <FontAwesomeIcon className='text-xl' icon={icon({name: 'house', style: 'solid'})} />
                    <h1 className='text-2xl font-light font-coolvetica'>Home</h1>
                </button>
                <button onClick={() => navigate('/educate')} className='flex justify-center gap-2 items-center text-white'>
                    <FontAwesomeIcon className='text-xl' icon={icon({name: 'book', style: 'solid'})} />
                    <h1 className='text-2xl font-light font-coolvetica'>Educate</h1>
                </button>
                <button onClick={() => navigate('/help')} className='flex justify-center gap-2 items-center text-white'>
                    <FontAwesomeIcon className='text-xl' icon={icon({name: 'phone', style: 'solid'})} />
                    <h1 className='text-2xl font-light font-coolvetica'>Helplines & Support</h1>
                </button>
            </section>
        </div>
    )
}

export default NavbarComponent