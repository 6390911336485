import { useEffect, useState } from "react"
import NavbarComponent from "../Components/Navbar"
import FooterComponent from "../Components/Footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Table from "../Components/LegalTable"

const images = [
    'ambulance.jpg',
    'carcrash.jpeg',
    'carcrash3.jpeg',
    'policestock.jpg'
]

const Landing = () => {
    const [intro, setIntro] = useState(0)
    const [image, setImage] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        var location = new URL(window.location.href)
        if(location.searchParams.get('introductionShown') !== '1') {
            setIntro(1)
            setTimeout(() => setIntro(2), 500)
            setTimeout(() => setIntro(3), 700)
            setTimeout(() => setIntro(4), 1200)
            setTimeout(() => setIntro(5), 1500)
            setTimeout(() => {
                setIntro(0)
                location.searchParams.set('introductionShown', '1');
                window.history.replaceState( {} , 'One life, one you.',  location.href);
            }, 3000)
        }

        setImage(Math.floor(Math.random()*images.length))
    }, [])

    return (
        <div className='min-h-screen min-w-full bg-black'>
            <img src={`/images/backgrounds/${images[image]}`} alt="Background" className="select-none absolute inset-0 w-full h-full object-cover z-10 blur-sm brightness-50 opacity-50" />
            {intro === 0 &&
            <div className='relative z-20 overflow-auto min-h-screen max-w-screen'>
                <NavbarComponent />
                <div className='max-w-screen md:max-w-5xl m-auto md:min-w-max p-5'>
                    <h1 className="text-white text-center font-akira text-xl">One life is lost, every <u>5</u> hours, from drug overdose.</h1>
                    <h1 className="text-center font-akira text-white text-1xl">One life, one you.</h1>
                    <h1 className="text-white text-left font-inter text-3xl mt-10 flex justify-start items-center gap-2 font-semibold">
                        <FontAwesomeIcon className='text-xl' icon={icon({name: 'heart', style: 'solid'})} />
                        Our Mission
                    </h1>
                    <h1 className="text-white text-left font-inter text-xl mt-3 flex justify-start items-center gap-2 text-clip max-w-5xl">
                        "One life, One You"'s primary mission is to educate Australians, specifically teenagers and young adults into the dangers of drugs and alchohol, and how they can affect your life, and the lives of others around you.
                        We aim to shed light on the legal issues and consequences of drug and alchohol abuse and also the health risks.
                    </h1>

                    <h1 className="text-white text-center font-akira text-xl mt-10 hidden md:block">"Make Every Choice Count: Say No to Drugs."</h1>
                        <div className='grid grid-rows-2 md:grid-cols-2 md:grid-rows-1 gap-1 mt-3 max-w-5xl p-3 border-2 border-white'>
                            <section className="min-w-full min-h-full">
                                <h1 className="text-white text-center font-akira text-xl">WHY DRUG DRIVE?</h1>
                                <h1 className="text-white text-left font-inter text-xl mt-2">This video demonstrates the effect on drugs have not only on your state of mind while intoxicated, but the after effects of actions you commit while intoxicated, how they affect your friends and family as well.</h1>
                                <h1 className="text-white text-left font-inter text-xl mt-2">Why drug drive if you won't drink drive. It's even worse.</h1>
                            </section>
                            <section className="min-w-full min-h-full relative">
                                {!loaded &&
                                <main className="z-10">
                                    <h1 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-inter z-20 text-white bg-black p-4'>
                                        If you are not seeing a video here, you may have an ad-blocker enabled OR your internet has blocked the YouTube iFrame.
                                    </h1>
                                </main>
                                }
                                <main className="md:hidden z-0">
                                    <iframe onLoad={() => setLoaded(true)} width="350" height="246" src="https://www.youtube.com/embed/n4X2lbxc5O4?si=4V35YlgxLHep1YQa&amp;start=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </main>
                                <main className="hidden md:block z-0">
                                    <iframe onLoad={() => setLoaded(true)} width="500" height="300" src="https://www.youtube.com/embed/n4X2lbxc5O4?si=4V35YlgxLHep1YQa&amp;start=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </main>
                            </section>
                        </div>

                    <h1 className="text-white text-left font-inter text-3xl mt-10 flex justify-start items-center gap-2 font-semibold mb-5">
                        <FontAwesomeIcon className='text-xl' icon={icon({name: 'handcuffs', style: 'solid'})} />
                        Are you aware of the legal troubles you can get into, for just posessing drugs?
                    </h1>
                    <Table />
                    <h1 className="font-inter font-semibold text-2xl text-white my-3">
                        Charges for Possessing Drugs
                    </h1>
                    <div className='mt-3 grid md:grid-cols-2 max-w-5xl text-white gap-2 gap-y-2'>
                        <section>
                            <h1 className="font-inter font-semibold text-xl">
                                Small Quantity
                            </h1>
                            <p className='font-inter text-lg'>
                                A supply charge for the small quantity is usually dealt with in the Local Court. <br/>The maximum penalty for supplying a small quantity is $5,500 and/or 2 years’ imprisonment.
                            </p>
                        </section>
                        <section>
                            <h1 className="font-inter font-semibold text-xl">
                                Trafficable Quantity
                            </h1>
                            <p className='font-inter text-lg'>
                            As outlined above, if there is more than the trafficable quantity, the deeming provision applies. This means that above this amount, police can charge someone with a supply charge, even if there is no evidence of an actual supply having taken or about to take place. <br/>The maximum penalty in the Local Court is $11,000 and/or 2 years’ imprisonment.
                            </p>
                        </section>
                        <section>
                            <h1 className="font-inter font-semibold text-xl">
                            Indictable Quantity but less than the Commercial Quantity
                            </h1>
                            <p className='font-inter text-lg'>
                                If the matter is dealt with in the District Court and the drug is not cannabis plant or leaf, the maximum penalty is $220,000 and/or 15 years’ imprisonment. <br/><br/>If the drug is cannabis plant or leaf, the maximum penalty is $220,000 and/or 10 years’ imprisonment.
                            </p>
                        </section>
                        <section>
                            <h1 className="font-inter font-semibold text-xl">
                                More than Commercial Quantity
                            </h1>
                            <p className='font-inter text-lg'>
                            If the drug is cannabis plant or leaf the maximum penalty is $385 000 and/or 15 years’ imprisonment.
                            <br/><br/>
                            If the drug is not cannabis plant or leaf, the maximum penalty is $385 000 and/or 20 years’ imprisonment.
                            </p>
                        </section>
                        <section>
                            <h1 className="font-inter font-semibold text-xl">
                                More than Large Commercial Quantity
                            </h1>
                            <p className='font-inter text-lg'>
                                If the drug is cannabis plant or leaf the maximum penalty is $550 000 and/or 20 years’ imprisonment.
                                <br/><br/>
                                If the drug is not cannabis plant or leaf, the maximum penalty is $550 000 and/or life imprisonment.
                            </p>
                        </section>
                        <section>
                            <h1 className="font-inter font-semibold text-xl">
                            Supply to persons less than 16 years of age
                            </h1>
                            <p className='font-inter text-lg'>
                                In all of the above categories, if the person being supplied to is less than 16 years of age, then the penalties are increased.
                            </p>
                        </section>
                    </div>

                    <div>
                        <div className='grid grid-rows-2 md:grid-cols-2 md:grid-rows-1 gap-1 mt-3 max-w-5xl p-3 border-2 border-white'>
                            <section className="min-w-full min-h-full">
                                <h1 className="text-white text-center font-akira text-xl">BE A ROLEMODEL, DRINK SMART</h1>
                                <h1 className="text-white text-left font-inter text-xl mt-2">This video by DrinkWise demonstrates to adults, specifically parents showing how their kids are prone to picking up their bad drinking habits.</h1>
                                <h1 className="text-white text-left font-inter text-xl mt-2">Why drug drive if you won't drink drive. It's even worse.</h1>
                            </section>
                            <section className="min-w-full min-h-full relative">
                                {!loaded &&
                                <main className="z-10">
                                    <h1 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-inter z-20 text-white bg-black p-4'>
                                        If you are not seeing a video here, you may have an ad-blocker enabled OR your internet has blocked the YouTube iFrame.
                                    </h1>
                                </main>
                                }
                                <main className="md:hidden z-0">
                                    <iframe onLoad={() => setLoaded(true)} width="350" height="246" src="https://www.youtube.com/embed/yiXvAo6o0HY?si=xIkyIOlC33tgd5AP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </main>
                                <main className="hidden md:block z-0">
                                    <iframe onLoad={() => setLoaded(true)} width="500" height="300" src="https://www.youtube.com/embed/yiXvAo6o0HY?si=xIkyIOlC33tgd5AP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </main>
                            </section>
                        </div>
                    </div>
                </div>
                <FooterComponent />
            </div>
            }
            {intro !== 0 &&
            <div className='relative z-20 flex justify-start items-center min-w-full min-h-screen'>
                <div className='flex-grow max-w-screen md:max-w-xl md:ml-20 flex flex-col justify-start items-start'>
                    <div className='flex gap-3'>
                        <h1 className={`text-5xl font-akira ${intro >= 2 ? 'text-white' : 'text-transparent'}`}>
                            One
                        </h1>
                        <h1 className={`text-5xl font-akira ${intro >= 3 ? 'text-white' : 'text-transparent'}`}>
                            life,
                        </h1>
                    </div>
                    <div className='flex gap-3'>
                        <h1 className={`text-7xl font-akira ${intro >= 4 ? 'text-white' : 'text-transparent'}`}>
                            One
                        </h1>
                        <h1 className={`text-7xl font-akira ${intro >= 5 ? 'text-white' : 'text-transparent'}`}>
                            you.
                        </h1>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Landing