import React from 'react';

const Table = () => {
  return (
    <div className="max-w-screen-lg mx-auto overflow-auto bg-black">
      <table className="min-w-full bg-transparent shadow-md overflow-none border-2 border-white">
        <thead className="text-white">
          <tr>
            <th className="py-2 px-3 text-left font-akira font-semibold text-1xl border-b">
              Prohibited Drug
            </th>
            <th className="py-2 px-3 text-left font-akira font-semibold text-1xl border-b">
              Small Quantity
            </th>
            <th className="py-2 px-3 text-left font-akira font-semibold text-1xl border-b">
              Trafficable Quantity
            </th>
            <th className="py-2 px-3 text-left font-akira font-semibold text-1xl border-b">
              Indictable Quantity
            </th>
            <th className="py-2 px-3 text-left font-akira font-semibold text-1xl border-b">
              Commercial Quantity
            </th>
            <th className="py-2 px-3 text-left font-akira font-semibold text-1xl border-b">
              Large Commercial Quantity
            </th>
          </tr>
        </thead>
        <tbody className="text-white">
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Cannabis Plant</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">5</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">–</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">50</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">250</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1000</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Cannabis Plant – enhanced indoor</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">5</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">–</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">50</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">50</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">200</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Cannabis Leaf</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">30 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">300 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1 kg</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">25 kg</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">100 kg</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Cannabis Resin</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">5 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">30 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">90 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">2.5 kg</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">10 kg</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Cannabis Oil</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">2 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">5 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">10 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">500 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">2 kg</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Cocaine</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">3 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">5 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">250 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1 kg</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Heroin</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">3 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">5 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">250 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1 kg</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Amphetamine</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">3 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">5 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">250 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1 kg</td>
          </tr>
          <tr>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">Ecstasy</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">0.25 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">0.75 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">1.25 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">125 g</td>
            <td className="py-2 px-3 font-inter font-regular text-lg border-b">0.5 kg</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
