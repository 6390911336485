import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'


const FooterComponent = () => {
    const navigate = useNavigate()

    return (
        <div className='min-w-full mt-auto py-10 border-t-2 border-white flex flex-col justify-center items-center'>
            <h1 className="text-center font-akira text-white text-1xl">One life, one you.</h1>
            <section className='gap-3 flex'>
                <button onClick={() => navigate('/?introductionShown=1')} className='flex justify-center gap-2 items-center text-white'>
                    <FontAwesomeIcon className='text-xl' icon={icon({name: 'house', style: 'solid'})} />
                    <h1 className='text-2xl font-light font-coolvetica'>Home</h1>
                </button>
                <button onClick={() => navigate('/help')} className='flex justify-center gap-2 items-center text-white'>
                    <FontAwesomeIcon className='text-xl' icon={icon({name: 'phone', style: 'solid'})} />
                    <h1 className='text-2xl font-light font-coolvetica'>Helplines & Support</h1>
                </button>
            </section>
            <h1 className='text-2xl font-inter font-black text-red-500 mt-5'>⚠ This is not an official website, this has been developed for a school project however features relevant information. If you are looking for actual help, please find an offical website, or redirect to the <a className='underline text-blue-400' href='https://findahelpline.com/'>Find A Helpline website </a>.</h1>
        </div>
    )
}

export default FooterComponent